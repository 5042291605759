import { trigger, style, animateChild, transition, animate, query } from '@angular/animations';
import { Overlay, OverlayRef } from '@angular/cdk/overlay';
import { TemplatePortal } from '@angular/cdk/portal';
import { DatePipe } from '@angular/common';
import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges, TemplateRef, ViewChild, ViewContainerRef } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { AuthenticationService } from 'src/app/services/authentication.service';
import { PinsService } from 'src/app/services/pins.service';

@Component({
  selector: 'app-pins-template-molecule',
  templateUrl: './pins-template-molecule.component.html',
  styleUrls: ['./pins-template-molecule.component.scss'],
  animations: [

    trigger('slide', [
      transition(':enter', [
        style({ opacity: 0 }),
        animate('100ms ease',
          style({ opacity: 1 })
        ),
        query("@*", [animateChild()], { optional: true })
      ]),
      transition(':leave', [
        query("@*", [animateChild()], { optional: false }),
      ]),
    ]),

    trigger('childAnimation', [
      transition(':enter', [
        style({ transform: 'translateX(100%)' }),
        animate('800ms cubic-bezier(0.2, 1, 0.3, 1)',
          style({ transform: 'translateX(0%)' })
        )
      ]),
      transition(':leave', [
        style({ transform: 'translateX(0%)' }),
        animate('300ms ease',
          style({
            transform: 'translateX(100%)',
            boxShadow: '0px 0 00px 0px rgba(87,73,86,0.0)'
          }
          )
        )
      ])
    ])

  ]
})
export class PinsTemplateMoleculeComponent implements OnInit, OnChanges {
  @ViewChild('overlayTemplate', { static: true }) overlayTemplate!: TemplateRef<any>;
  @Input() data: any;
  @Input() header: any;
  @Input() cachedPinCounts: string[];
  @Output() fetchPinCount = new EventEmitter<void>();
  isLoading: boolean = true;
  count: number | null = null;
  overlayRef!: OverlayRef;
  showList: boolean = false;
  editing: boolean = false;
  private _userName: string | null = null;
  isPrivate: boolean = false;
  cachedPins: { [key: string]: number } = {};
  pinCounts: { [key: string]: number } = {};
  createdOn: string | null = null;
  createdBy: string | null = null;
  items:any = [];
  form!: FormGroup;
  commentForm: FormGroup;
  editItemId: any;
  commentsList: any

  constructor(private overlay: Overlay, private viewContainerRef: ViewContainerRef, private fb: FormBuilder,private dialog: MatDialog,
    private authenticationService: AuthenticationService, private pinsService: PinsService, private snack: MatSnackBar) {
  }

  ngOnInit() {
    this.initForm();
  }
  ngOnChanges(changes: SimpleChanges) {
    if (changes.cachedPinCounts) {
      this.isLoading = !changes.cachedPinCounts.currentValue;
    }
  }


  createOverlay() {
    if (!this.overlayRef) {
      this.overlayRef = this.overlay.create({
        hasBackdrop: true,
        backdropClass: 'overlay-backdrop',
        positionStrategy: this.overlay.position().global().right('0px').top('0px'),
        scrollStrategy: this.overlay.scrollStrategies.block(),
        height: '100vh',
        width: '400px'
      });
      console.log(this.overlayRef,"ref")

      const templatePortal = new TemplatePortal(this.overlayTemplate, this.viewContainerRef);
      this.overlayRef.attach(templatePortal);

      this.overlayRef.backdropClick().subscribe(() => {
        this.closeOverlay();
      });
    }
  }
  closeOverlay() {
    if (this.overlayRef) {
      if (this.editing) {
        this.editing = false;
        this.commentsList = [];
        this.editItemId = null;
        this.showList = true;
      } else {
        this.showList = false;
        this.overlayRef.dispose();
        this.overlayRef = null!;
      }
      this.form.reset({
        code: this.data,
        description: '',
        keepPrivate: false
      });
      this.commentForm.reset();
      this.isPrivate = false;
    }

  }
  private initForm() {
    this.form = this.fb.group({
      code: [{ value: this.data, disabled: true }],
      description: ['', Validators.required],
      keepPrivate: [false],
    });
    this.commentForm = this.fb.group({
      comment: ['', Validators.required]
    });
  }
  getCount(code: string): number {
    return this.cachedPinCounts[code] !== null ? this.cachedPinCounts[code] : 0; // Return count or 0 if not set
  }
  createPin() {
    this.showList = false;
    this.editing = false;
    this.createOverlay();
  }
  createComment() {
    const payload = {
      code: this.editItemId,
      ...this.commentForm.value,
    }
    console.log(payload, "comment payload")
    this.pinsService.createComment(payload).subscribe((res: any) => {
      console.log("Response from create comment Api", res);
      this.fetchComments(this.editItemId);
      this.commentForm.reset();
    })

  }

  pinsOverview(id: any) {
    this.showList = true;
    console.log(id, "over")
    this.pinsService.getPinsList(id).subscribe((res: any) => {
      this.items = res;
      console.log(this.items, "items");
      if (this.items.length > 0) {
        this.createOverlay();
      }
    }
    );
  }

  editPin(item: any) {
    const datePipe = new DatePipe('en-US');
    this.editItemId = item.id;
    this.showList = false;
    this.editing = true;
    this.fetchComments(this.editItemId);
    this.createOverlay();
    this.form.patchValue({
      code: item.code,
      description: item.description,
      keepPrivate: item.keepPrivate,
    });
    this.isPrivate = item.keepPrivate;
    this.createdOn = datePipe.transform(item.recordDate, 'MMM dd,yyyy h:mma');
    this.createdBy = item.createdBy;
  }
  fetchComments(id: any) {
    this.pinsService.getCommentsList(id).subscribe((res) => {
      this.commentsList = res;
      console.log('Comment list', res);
    });
  }
  deleteItem(templateRef: TemplateRef<any>,id: any) {
      const dialogRef = this.dialog.open(templateRef, {
        width: '300px',
      });
  
      dialogRef.afterClosed().subscribe(result => {
        if (result === 'delete') {
          if (this.showList) {
            this.pinsService.deletePin(id).subscribe((res) => {
              this.showSnackbar('Deleted successfully');
              console.log('Response from save API:', res);
              this.pinsOverview(this.data);
              if (this.items.length === 1) {
                this.closeOverlay();
              }
              this.fetchPinCount.emit();
            },
              (error) => {
                this.showSnackbar('Unable to create pin');
                console.log(error);
                this.closeOverlay();
              })
          } else {
            this.pinsService.deletePin(id).subscribe((res) => {
              this.showSnackbar('Deleted successfully');
              console.log('Response from save API:', res);
              this.closeOverlay();
              this.pinsOverview(this.data);
              if (this.items.length === 1) {
                this.closeOverlay();
              }
              this.fetchPinCount.emit();
            },
              (error) => {
                this.showSnackbar('Unable to create pin');
                console.log(error);
                this.closeOverlay();
              })
          }
        } else {
          console.log('Delete canceled');
        }
      })
  }
  getCommentUser(name: string): string {
    return name
      .split(/[_\s]+/)
      .map(value => value.charAt(0).toUpperCase())
      .join("");
  }
  get userName(): string {
    if (this._userName === null) {
      this._userName = this.getName();
    }
    return this._userName;
  }
  getName() {
    console.log("getName called");
    let name = this.authenticationService.getUserName();
    if (!name) {
      return "";
    }
    console.log(name, "nnnn")
    const userName = name.split(" ").map(value => value.charAt(0).toUpperCase());
    return userName.slice(0, 2).join("");
  }
  togglePrivacy(id?: any): void {
    console.log(id, "id")
    const item = this.items.find(i => i.id === id);
    if (item) {
      this.isPrivate = !item.keepPrivate;
      item.keepPrivate = this.isPrivate;
      this.form.patchValue({
        keepPrivate: this.isPrivate,
      });
      const payload = {
        id: id,
        keepPrivate: this.isPrivate,
      }
      this.pinsService.updatePin(payload).subscribe((res) => {
        console.log('Response from save API:', res);
        if (this.showList) {
          this.pinsOverview(this.data);
        }
      });
    } else {
      this.isPrivate = !this.isPrivate;
      this.form.patchValue({
        keepPrivate: this.isPrivate,
      });
    }
  }
  showSnackbar(message: string) {
    this.snack.open(message, 'close', {
      duration: 4000,
      horizontalPosition: 'center',
      verticalPosition: 'top'
    });
  }
  saveForm(): void {
    if (this.form && this.form.valid) {
      const payload = this.form.getRawValue();
      if (!this.editing) {
        this.pinsService.createPin(payload).subscribe((res) => {
          this.showSnackbar('Created pin successfully!');
          console.log('Response from save API:', res);
          this.closeOverlay();
          this.fetchPinCount.emit();
        },
          (error) => {
            this.showSnackbar('Unable to create pin');
            console.log(error);
            this.closeOverlay();
          })
      }

    }
  }
  updatePin(id: any) {
    console.log(id, "in update");
    const payload = { id: id, ...this.form.getRawValue() };
    this.pinsService.updatePin(payload).subscribe((res) => {
      console.log('Response from save API:', res);
      this.form.get('description')?.markAsPristine();
    },
      (error) => {
        this.showSnackbar('Unable to update pin');
        console.log(error);
        this.closeOverlay();
      })
  }
}
