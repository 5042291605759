import { Component,Input, ViewChild, ElementRef, AfterViewChecked, ChangeDetectorRef, HostListener} from '@angular/core';
import { ChatbotService } from 'src/app/services/chatbot.service';
import { Router , ActivatedRoute} from "@angular/router";
import { chatbotAnimation } from 'src/app/properties/animation';
import { AuthenticationService } from 'src/app/services/authentication.service';
import { DatePipe } from "@angular/common";
import { ClickEventArgs } from "@syncfusion/ej2-navigations";
import { ClickStreamAnalyticsService, EventType } from "src/app/services/clickstream-analytics-service";
import { GridComponent } from '@syncfusion/ej2-angular-grids';
import { FilterSettingsModel , FilterSearchBeginEventArgs, } from '@syncfusion/ej2-angular-grids';
import { AppSettingService } from 'src/app/services/app-setting.service';

type Message = {
  sender: string;
  text?: string;
  tableData?: any[];
  tableId?: string;
};
@Component({
  selector: 'app-chatbot',
  templateUrl: './chatbot.component.html',
  styleUrls: ['./chatbot.component.scss'],
  animations: [chatbotAnimation]
})
export class ChatbotComponent {
  // messages: Message[] = [];
  @ViewChild('chatWindow') private chatWindow: ElementRef;
  showArrow = false;
  userInput: string = '';
  messages: { sender: string, text?: string, tableHeaders?: any[],tableData?: any[], tableId?: string }[] = [];
  isInputFocused = false;
  gridData: any[] = [];
  headers: any[] = [];
  @ViewChild("grid", { static: false })
  public grid: GridComponent;
  summarise: boolean = true;
  loading: boolean = false;
  // threadId: null;
  // headers: any[] = [];
  threadId: string = '';
  userId: string| null= null;
  public datePipe = new DatePipe("en-US");
  shouldScrollToBottom: boolean = true;
  showSamplePrompt = true;
  available_percent : number ;
  credit_limit: number;
  token_used: number;
  available_limit:number;



  constructor( private settingService: AppSettingService,private chatbotService: ChatbotService, private clickStream: ClickStreamAnalyticsService,private route: ActivatedRoute, private router: Router, private authenticationService: AuthenticationService,private cd: ChangeDetectorRef) {}
 
  ngOnInit() {
    this.loading = true;
    setTimeout(() => {
      this.loading = false;
      this.getName()
    }, 5000);
    this.route.queryParams.subscribe(params => {
      if (params['summarise']) {
        this.summarise = false;
        this.loading = false;
      }
    });
    this.getAvailableCredit()
  }
  public filterSettings: FilterSettingsModel = {
    type: 'Excel'
  };

  getAvailableCredit(){
    this.chatbotService.getCredit().subscribe(
      (response) => {
        // Handle the successful response here
        this.available_percent = response['available_percent'];
        this.credit_limit = response['credit_limit'];
        this.token_used = response['token_used'];
        this.available_limit = this.credit_limit - this.token_used;
        console.log('Credit data:', response);
      },
      (error) => {
        // Handle the error here
        console.error('Credit data......Error fetching credit data:', error);
      }
    );
  }


  @HostListener('window:scroll', [])
  onWindowScroll(): void {
    const bottomOffset = 50; // Adjust based on when you want the arrow to appear
    const scrollPosition = window.innerHeight + window.scrollY;
    const pageHeight = document.documentElement.scrollHeight;

    this.showArrow = scrollPosition < (pageHeight - bottomOffset);
  }

  scrollToBottom1(): void {
    window.scrollTo({ top: document.documentElement.scrollHeight, behavior: 'smooth' });
  }
  // ngAfterViewChecked(): void {
  //   // this.cd.detectChanges(); 
  //   // this.scrollToBottom();
   
  //     // setTimeout(() => this.scrollToBottom(), 0);
  //     if (this.shouldScrollToBottom) {
  //       setTimeout(() => this.scrollToBottom(), 0);
  //     }
  
  // }

  // private scrollToBottom(): void {
  //   // const chatWindow = this.chatWindow.nativeElement;
  //   // chatWindow.scrollTop = chatWindow.scrollHeight;
  //   try {
  //     this.chatWindow.nativeElement.scrollTop = this.chatWindow.nativeElement.scrollHeight;
  //   } catch(err) { }
  // }

  private getExcelExportProperties(): any {
    let currentDateTime = this.datePipe.transform(
      new Date(),
      "MM_dd_yy_hh:mm:ss"
    );
    return {
      fileName: "flowsmart_datagrid" + "_" + currentDateTime + ".xlsx",
    };
  }
  toolbarClick(args: ClickEventArgs): void {
    this.clickStream.publishGAEvent(EventType.GRID_COLUMN_ACTION, {
      actionType: args.item.text,
      section: "openai_toolbar"
    })
    switch (args.item.text) {
      case "Excel Export":
        this.grid.excelExport(this.getExcelExportProperties());
        break;
    }
  }
 
 
  sendMessage(samplePromptText?: string) {
    const userMessage = samplePromptText ? samplePromptText : this.userInput.trim();
    this.showSamplePrompt = false;
    this.loading = true;
  
    if (userMessage) {
      this.messages.push({ sender: 'user', text: userMessage });
      this.clickStream.publishGAEvent(EventType.OPENAI_CHAT, {
        actionType: "chat",
        section: "openai_searchbox"
      });
  
      this.chatbotService.getResponse(userMessage, this.threadId).subscribe(response => {
        if (response.threadId) {
          this.threadId = response.threadId;
        }
        response.messages.forEach((msg: any, index: number) => {
          if (msg.type === 'TEXT') {
            const message = msg.content;
            const typingSpeed = 10;
  
            const typeMessage = (message: string, index = 0) => {
              if (index < message.length) {
                if (this.messages.length && this.messages[this.messages.length - 1].sender === 'bot') {
                  this.messages[this.messages.length - 1].text = message.slice(0, index + 1);
                } else {
                  this.messages.push({ sender: 'bot', text: message.slice(0, index + 1) });
                }
                setTimeout(() => typeMessage(message, index + 1), typingSpeed);
              } else {
                if (this.messages.length && this.messages[this.messages.length - 1].sender === 'bot') {
                  this.messages[this.messages.length - 1].text = message;
                } else {
                  this.messages.push({ sender: 'bot', text: message });
                }
              }
            };
  
            setTimeout(() => typeMessage(message), 500);
  
          } else if (msg.type === 'TABLE') {
            this.clickStream.publishGAEvent(EventType.OPENAI_CHAT, {
              actionType: "table_rendered",
              section: "openai_searchbox"
            });
  
            // Ensure unique ID for each table
            const tableId = `table-${index}-${new Date().getTime()}`;
            const tableData = JSON.parse(msg.content);
            const tableHeaders = JSON.parse(msg.columns);
  
            this.messages.push({ sender: 'bot',tableHeaders: tableHeaders, tableData: tableData, tableId: tableId });
          }
        });
        this.loading = false;
      });
  
      if (!samplePromptText) {
        this.userInput = '';
      }
    }
  }
  

  getName() {
    if(this.userId != null){
      return this.userId;
    }
    try{
    let name = this.authenticationService.getUserName();
    let names = name?.split(" ");
    let nameId = names
      .map((value) => {
        return value.charAt(0);
      })
      .slice(0, 2)
      .join("")
      .toUpperCase();
      this.userId = nameId;
      return nameId;
    }catch(e){
      console.error(e);
      return 'U';
    }
  }

  closeChatbot() {
    this.router.navigate(['/']);
  }

  onInputFocus() {
    this.isInputFocused = true;
  }

  onInputBlur() {
    this.isInputFocused = false;
  }

  isButtonDisabled(): boolean {
    return !this.userInput.trim();
  }
}
