<ng-container [@modalAnimation] *ngIf="formData">

  
  <mat-toolbar>
    Material Form
    <div class="pt-2 pl-2" class="close_icon">
        <div class="modal_close" (click)="dialogRef.close()">
          <span class="material-icons">+</span>
        </div>
      </div>
</mat-toolbar>
  
  
  <app-dynamic-form-molecule *ngIf="formData" [formData]="formData" [addFlag]="addFlag" (submitForm)="submitForm($event)"></app-dynamic-form-molecule>
</ng-container>