import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'getChangePercent'
})
export class TrendComparator implements PipeTransform {

  transform(value1: string, value2: string): string {
    if (value1 && value2) {
      const num1 = parseFloat(value1);
      const num2 = parseFloat(value2);

      // Check for valid numbers
      if (isNaN(num1) || isNaN(num2)) {
        return '';
      }
      if (num1 != num2) {
        const difference = Math.abs(num1 - num2);
        const average = (num1 + num2) / 2;
        const percentDifference = (difference / average) * 100;
        // Format to 2 decimal places
        return percentDifference.toFixed(0) + "%";
      }

    }
    return '';
  }

}

@Pipe({
  name: 'getChangeDirection'
})
export class TrendDirection implements PipeTransform {

  transform(value1: string, value2: string): string {
    if (value1 && value2) {
      const num1 = parseFloat(value1);
      const num2 = parseFloat(value2);

      // Check for valid numbers
      if (isNaN(num1) || isNaN(num2)) {
        return 'trend-hide';
      }
      if (num1 > num2) {
        return 'trend-positive'
      } else if (num1 < num2) {
        return 'trend-negative'
      } else {
        return 'trend-hide';
      }

    }
    return 'trend-hide';
  }

}

