import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { api } from '../properties/endpoints';

@Injectable({
  providedIn: 'root'
})
export class PinsService {

  constructor(private http: HttpClient) { }

  createPin(payload:any){
    const url = `${api.host}/material/pins/create`;
    return this.http.put(url,payload);
  }
  deletePin(id: any){
    const url = `${api.host}/material/pins/delete/${id}`;
    return this.http.delete(url,{responseType: 'text'});
  }
  updatePin(payload:any){
    const url = `${api.host}/material/pins/update`;
    return this.http.post(url,payload);
  }
  getPinsCount(payload:any){
    const url = `${api.host}/material/pins/count`;
    return this.http.post(url,payload);
  }
  getPinsList(code:any){
    const url=`${api.host}/material/pins/list//${code}`
    return this.http.get(url);
  }
  createComment(payload:any){
    const url = `${api.host}/material/pins/comments/create`;
    return this.http.put(url,payload);
  }
  getCommentsList(pinId:any){
    const url = `${api.host}/material/pins/comments/list/${pinId}`;
    return this.http.get(url);
  }
}
