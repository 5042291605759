<div class="forecast-container">
    <div class="left-column">
      <mat-toolbar class="d-flex justify-content-around header header-background">
        <span class="headline">Gynoveda Forecast</span>
      </mat-toolbar>
      <ul>
        <li class="forecast-list d-flex justify-content-between " [class.active]="i === activeIndex"
          (click)="handleItemClick(item, i)"
          [ngStyle]="{'color': item.active && i === activeIndex ? '#ffffff' : (item.active ? '#ffa000' : ''),'font-weight': i === activeIndex ? 'bold' : 'normal'}"
          *ngFor="let item of listScenarios; let i = index">
          {{item.name}}
          <button *ngIf="item.demandConversionDate" style="font-size: 18px;" mat-icon-button
            [matTooltip]="getMessage(item)" matTooltipPosition="above" matTooltipShowDelay="500">
            <mat-icon [inline]="true">info</mat-icon>
          </button>
        </li>
      </ul>
      <div class="actions">
        <button class="mat-button mat-primary mat-raised-button"
          type="submit" (click)="createNewScenario()">
          <mat-icon matTooltip="Create">note_add</mat-icon> Create
        </button>
      </div>
    </div>
    <div class="right-column">
        <app-forecastgyno-form-molecule *ngIf="renderScenario"
          (scenarioUpdated)="onScenarioUpdated($event)" [formData]="formData"
          [scenarioDate]="formData?.demandConversionDate"
          (dataEmitForPOPUPOUTER)="handlePOPUP($event)"></app-forecastgyno-form-molecule>
          <!-- <app-forecastgyno-form-molecule></app-forecastgyno-form-molecule> -->
    
        <app-popup-reuse-organsim *ngIf="fieldData && headerData" [showPOPUP]="visiblePOPUP" [fieldData]="fieldData"
          [headerData]="headerData" (POPUPFeedback)="visiblePOPUP=$event"></app-popup-reuse-organsim>
      </div>
  </div>