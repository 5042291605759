import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { RouterModule } from "@angular/router";
import { LayoutModule } from "@angular/cdk/layout";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import {
  GridModule,
  FilterService,
  PageService,
  SortService,
  GroupService,
  ColumnChooserService,
  GridAllModule,
} from "@syncfusion/ej2-angular-grids";
import { MaterialModule } from "../../material.module";
import { ServicesModule } from "../../services/services.module";
import { LoginMoleculeComponent } from "./login-molecule/login-molecule.component";
import { RegisterMoleculeComponent } from "./register-molecule/register-molecule.component";
import { MaterialTableMoleculeComponent } from "./material-management/material-table-molecule/material-table-molecule.component";
import { MatTableModule } from "@angular/material/table";
import { MatPaginatorModule } from "@angular/material/paginator";
import { MatSortModule } from "@angular/material/sort";
import { DynamicFormMoleculeComponent } from "./dynamic-form-molecule/dynamic-form-molecule.component";
import { DynamicFormFieldMoleculeComponent } from "./dynamic-form-field-molecule/dynamic-form-field-molecule.component";
import { ParameterTableMoleculeComponent } from "./parameter-management/parameter-table-molecule/parameter-table-molecule.component";
import { ReportsMoleculeComponent } from "./reports-molecule/reports-molecule.component";
import { StockCriteriaTableComponent } from "./stock-management/stock-criteria-table/stock-criteria-table.component";
import { ShareOfBusinessTableMoleculeComponent } from "./shareofbusiness-management/shareofbusiness-table-molecule/shareofbusiness-table-molecule.component";
import { DynamicNormTableMoleculeComponent } from "./dynamicnorm-management/dynamicnorm-table-molecule/dynamicnorm-table-molecule.component";
import { LocationsTableMoleculeComponent } from "./locations-management/locations-table-molecule/locations-table-molecule.component";
import { TableMoleculeComponent } from "./table-molecule/table-molecule.component";
import { UserTableMoleculeComponent } from "./user-managment/user-table-molecule/user-table-molecule.component";
import { BulkUploadMoleculeComponent } from "./bulk-upload-molecule/bulk-upload-molecule.component";
import { ReportsFilterMoleculeComponent } from "./reports-filter-molecule/reports-filter-molecule.component";
import { LoaderComponent } from "./loader/loader.component";
import { ForgetPasswdComponent } from "./forget-passwd/forget-passwd.component";
import { PlanningStockTableMoleculeComponent } from "./planning-stock-table-molecule/planning-stock-table-molecule.component";
import { InnerPlanningstockComponent } from "./inner-planningstock/inner-planningstock.component";
import { BprStatusHistoryComponent } from "./bpr-status-history/bpr-status-history.component";
import { StockPlanningTabComponent } from "./stock-planning-tab/stock-planning-tab.component";
import { StockPlanningTableComponent } from "./stock-planning-table/stock-planning-table.component";
import { TableSkeltonLoaderComponent } from "./table-skelton-loader/table-skelton-loader.component";
import { SyncfusionGridComponent } from "./syncfusion-grid/syncfusion-grid.component";
// import { DropDownListModule } from '@syncfusion/ej2-angular-dropdowns';
import {
  DropDownListAllModule,
  DropDownListModule,
} from "@syncfusion/ej2-angular-dropdowns";
import { BufferTrendMoleculesComponent } from "./buffer-trend-molecules/buffer-trend-molecules.component";
import { UploadFilesMoleculesComponent } from "./upload-files-molecules/upload-files-molecules.component";
import { DialogModule } from "@syncfusion/ej2-angular-popups";
import { HistoricalReportsMoleculesComponent } from "./historical-reports-molecules/historical-reports-molecules.component";
// import { NgChartjsModule } from 'ng-chartjs';
import { NgChartsModule } from "ng2-charts";
import { DashboardChatjsMoleculeComponent } from "./dashboard-chatjs-molecule/dashboard-chatjs-molecule.component";
import { DashboardGridsMoleculeComponent } from "./dashboard-grids-molecule/dashboard-grids-molecule.component";
import { TabContainerMoleculeComponent } from "./tab-container-molecule/tab-container-molecule.component";
import { FilterMoleculeComponent } from "./filter-bay-molecule/filter-molecule.component";
import { BusinessScoreCardComponent } from "./business-score-card/business-score-card.component";
import { SalesBreakdownMoleculeComponent } from "./sales-breakdown-molecule/sales-breakdown-molecule.component";

import { PipesModule } from "../pipes/pipes.module";
import { SalesVisualizationMoleculeComponent } from "./sales-visualization-molecule/sales-visualization-molecule.component";
import { GridListMoleculeComponent } from "./grid-list-molecule/grid-list-molecule.component";
import { DetailPageMoleculeComponent } from "./detail-page-molecule/detail-page-molecule.component";
import { InfiniteScrollModule } from "ngx-infinite-scroll";
import { GridDetailSalesForecastMoleculeComponent } from "./grid-detail-sales-forecast-molecule/grid-detail-sales-forecast-molecule.component";
import { FilterBayReusableComponent } from "./filter-bay-reusable/filter-bay-reusable.component";
import { CachingMoleculeComponent } from "./caching-molecule/caching-molecule.component";
import { ProductCardMoleculeComponent } from "./product-card-molecule/product-card-molecule.component";
import { PuRosMoleculeComponent } from "./pu-ros-molecule/pu-ros-molecule.component";
import { PuStockTrendMoleculeComponent } from "./pu-stock-trend-molecule/pu-stock-trend-molecule.component";
import { PuBprMoleculeComponent } from "./pu-bpr-molecule/pu-bpr-molecule.component";
import { DetailPageAccordianMoleculeComponent } from './detail-page-accordian-molecule/detail-page-accordian-molecule.component';
import { DateMonthRangeMoleculeComponent } from './date-month-range-molecule/date-month-range-molecule.component';
import { MaterialMasterFilterMoleculeComponent } from './material-master-filter-molecule/material-master-filter-molecule.component';
import { ForecastingFormMoleculeComponent } from './forecasting-form-molecule/forecasting-form-molecule.component';
import { MatProgressBarModule } from "@angular/material/progress-bar";
// import { OrganismsModule } from "../organisms/organisms.module";
import { BOMAvailabilityState } from './bom-availability-state-molecule/bom-availability-state.component';
import { MatSelectFilterModule } from 'mat-select-filter';
import { MatSelectModule } from '@angular/material/select';
import { MatFormFieldModule } from '@angular/material/form-field';
import { WorkflowConfigureModalMoleculeComponent } from './workflow-configure-modal-molecule/workflow-configure-modal-molecule.component';
import { DownloadMoleculeComponent } from './download-molecule/download-molecule.component';
import { PinsTemplateMoleculeComponent } from './pins-template-molecule/pins-template-molecule.component';
import { OverlayModule } from "@angular/cdk/overlay";
import { ForecastgynoFormMoleculeComponent } from './forecastgyno-form-molecule/forecastgyno-form-molecule.component';

const components = [
  LoginMoleculeComponent,
  RegisterMoleculeComponent,
  MaterialTableMoleculeComponent,
  DynamicFormMoleculeComponent,
  DynamicFormFieldMoleculeComponent,
  ParameterTableMoleculeComponent,
  ReportsMoleculeComponent,
  StockCriteriaTableComponent,
  LocationsTableMoleculeComponent,
  TableMoleculeComponent,
  UserTableMoleculeComponent,
  ShareOfBusinessTableMoleculeComponent,
  DynamicNormTableMoleculeComponent,
  BulkUploadMoleculeComponent,
  ReportsFilterMoleculeComponent,
  LoaderComponent,
  SyncfusionGridComponent,
  LoaderComponent,
  BufferTrendMoleculesComponent,
  UploadFilesMoleculesComponent,
  HistoricalReportsMoleculesComponent,
  DashboardChatjsMoleculeComponent,
  DashboardGridsMoleculeComponent,
  FilterMoleculeComponent,
  BusinessScoreCardComponent,
  SalesVisualizationMoleculeComponent,
  SalesBreakdownMoleculeComponent,
  FilterBayReusableComponent,
  CachingMoleculeComponent,
  ProductCardMoleculeComponent,
  PuRosMoleculeComponent,
  PuStockTrendMoleculeComponent,
  PuBprMoleculeComponent,
  DetailPageMoleculeComponent,
  MaterialMasterFilterMoleculeComponent,
  BOMAvailabilityState,
  ForecastingFormMoleculeComponent,
  WorkflowConfigureModalMoleculeComponent,
  DownloadMoleculeComponent,
  ForecastgynoFormMoleculeComponent,

];
@NgModule({
  declarations: [
    ...components,
    ForgetPasswdComponent,
    PlanningStockTableMoleculeComponent,
    InnerPlanningstockComponent,
    BprStatusHistoryComponent,
    StockPlanningTabComponent,
    StockPlanningTableComponent,
    TableSkeltonLoaderComponent,
    SyncfusionGridComponent,
    BufferTrendMoleculesComponent,
    UploadFilesMoleculesComponent,
    HistoricalReportsMoleculesComponent,
    DashboardChatjsMoleculeComponent,
    DashboardGridsMoleculeComponent,
    TabContainerMoleculeComponent,
    FilterMoleculeComponent,
    BusinessScoreCardComponent,
    SalesVisualizationMoleculeComponent,
    GridListMoleculeComponent,
    DetailPageMoleculeComponent,
    GridDetailSalesForecastMoleculeComponent,
    FilterBayReusableComponent,
    CachingMoleculeComponent,
    PuRosMoleculeComponent,
    PuStockTrendMoleculeComponent,
    PuBprMoleculeComponent,
    DetailPageAccordianMoleculeComponent,
    DateMonthRangeMoleculeComponent,
    MaterialMasterFilterMoleculeComponent,
    ForecastingFormMoleculeComponent,
    WorkflowConfigureModalMoleculeComponent,
    PinsTemplateMoleculeComponent,
  ],
  imports: [
    CommonModule,
    LayoutModule,
    RouterModule,
    MaterialModule,
    ServicesModule,
    MatTableModule,
    MatPaginatorModule,
    MatProgressBarModule,
    MatSortModule,
    FormsModule,
    ReactiveFormsModule,
    GridModule,
    DropDownListModule,
    DialogModule,
    NgChartsModule,
    PipesModule,
    InfiniteScrollModule,
    MatSelectFilterModule,
    MatSelectModule,
    MatFormFieldModule,
    OverlayModule
  ],
  providers: [
    GridAllModule,
    DropDownListAllModule,
    PageService,
    SortService,
    FilterService,
    GroupService,
    ColumnChooserService,
  ],
  exports: [...components],
})
export class MoleculesModule {}
