

<mat-toolbar>
    Share Of Business Form
    <div class="pt-2 pl-2 close_icon">
        <div class="modal_close"  (click)="onCancel()">
            <span class="material-icons">
               +
            </span>
        </div>
    </div>
</mat-toolbar>

<app-dynamic-form-molecule *ngIf="formData" [formData]='formData' [addFlag]='addFlag'  (submitForm)="submitForm($event)"></app-dynamic-form-molecule>
