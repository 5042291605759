import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { FormBase } from '../../../interfaces/formInterface';
import { FormControlService } from '../../../services/form-control.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute } from '@angular/router';


@Component({
  selector: 'app-dynamic-form-molecule',
  templateUrl: './dynamic-form-molecule.component.html',
  styleUrls: ['./dynamic-form-molecule.component.scss']
})
export class DynamicFormMoleculeComponent implements OnInit {
  @Input() formData: FormBase<any>[] = [];
  @Input() addFlag: any;
  @Output() submitForm = new EventEmitter<any>();
  formName;

  constructor(private fcs: FormControlService, private snack: MatSnackBar,private route: ActivatedRoute) {
    const currentUrl = this.route.snapshot.url;
    this.formName=currentUrl[0]?.path;
  }
  form: UntypedFormGroup;
  ngOnInit(): void {
    this.form = this.fcs.toFormGroup(this.formData);
  }
  save() {
    this.submitForm.emit(this.f);
  }

  get f() { return this.form.controls; }

}
