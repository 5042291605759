import { Component, OnInit, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { shareOfBusinessColumns } from '../../../../properties/tableConfig';
import { ShareOfBusinessService } from '../../../../services/shareofbusiness.service';
import { MatTableDataSource } from '@angular/material/table';
import { CommonUtilityService } from '../../../../services/common-utility-service';
import { SearchFilterService } from 'src/app/services/search-filter.service';
import { BulkUploadServiceService } from 'src/app/services/bulk-upload-service.service';
import { SyncfusionService } from 'src/app/services/syncfusion.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { AppSettingService } from 'src/app/services/app-setting.service';
import { MatDialog } from '@angular/material/dialog';
import { ShareOfBusinessFormOrganismComponent } from 'src/app/shared/organisms/shareofbusiness-management/shareofbusiness-form-organism/shareofbusiness-form-organism.component';
@Component({
  selector: 'app-shareofbusiness-table-molecule',
  templateUrl: './shareofbusiness-table-molecule.component.html',
  styleUrls: ['./shareofbusiness-table-molecule.component.scss']
})
export class ShareOfBusinessTableMoleculeComponent implements OnInit {

  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;
  ELEMENT_DATA: any[];
  dataSource:MatTableDataSource<any>;
  displayedColumns = shareOfBusinessColumns;
  add = false;
  update = false;
  constructor(private service: ShareOfBusinessService, 
    private utilService: CommonUtilityService, 
    private searchService: SearchFilterService,
    private bulkService: BulkUploadServiceService,
    private syncfusion: SyncfusionService,
    private settingService: AppSettingService,
    private snack: MatSnackBar,
    public dialog: MatDialog) {}
  searchField;

  clearSearchField() {
    this.searchField = '';
    this.dataSource = new MatTableDataSource<any>(this.ELEMENT_DATA);
  }

  ngOnInit() {
   this.service.getAllShareOfBusiness().subscribe((res: any) => {
      this.ELEMENT_DATA = res.shareOfBusinessList;
      this.dataSource = new MatTableDataSource<any>(this.ELEMENT_DATA);
      this.dataSource.sort = this.sort;
      this.dataSource.paginator = this.paginator;
   });
   const accessRules = JSON.parse(this.utilService.getCookie('accessRules')).accessRules;
   accessRules.map(each => {
    if (each.entity === 'shareofbusiness') {
        this.add = each.create;
        this.update = each.update;
    }
   });
  }
  public doFilter = (value: string) => {
    //this.dataSource.filter = value.trim().toLocaleLowerCase();
    if(!value) {
      this.dataSource = new MatTableDataSource<any>(this.ELEMENT_DATA);
      this.dataSource.sort = this.sort;
      this.dataSource.paginator = this.paginator;
    }  
       
    else this.searchService.searchFilter('shareofbusiness', value, 'materialCode').subscribe((res: any) => {
      this.dataSource = new MatTableDataSource<any>(res.shareOfBusinessList);
    })
  }

  openFormDialog(id: string = null) {
    // Open the modal dialog and pass the ID if available
    const dialogRef = this.dialog.open(ShareOfBusinessFormOrganismComponent, {
      width: '400px', // Adjust the modal width
      position: { right: '0px' , top: '64px'} ,
      data: { sobid: id }  // Pass the ID for editing, or null for adding new
    });

    dialogRef.afterClosed().subscribe(result => {
      // Refresh the table if any changes were made
      if (result) {
        this.ngOnInit();  // Reload the data after closing
      }
    });
  }

  downLoadReport() {
    // this.bulkService.downLoadReport('shareofbusiness');
    if(this.settingService.isRemoteGridMode()){
      const data = {
        downloadEntity: true
      };
      const reportName = 'Share_of_Business';
      this.syncfusion.exportDataToExcel(data,reportName).subscribe((response) => {
        if (response.status === 307) {
          this.bulkService.downLoadReport('shareofbusiness');
        } else {
          this.snack.open("Download triggered Successfully", "close", {
            duration: 4000,
            horizontalPosition: "center",
            verticalPosition: "top",
          });
        }
      }, (error) => {
        console.error('Error in Excel Export:', error);
      });

    }else{
      this.bulkService.downLoadReport('shareofbusiness');
    } 
  }

}
