import { Component, OnInit, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { stockCriteriaColumns } from '../../../../properties/tableConfig';
import { StockService } from '../../../../services/stock.service';
import { MatTableDataSource } from '@angular/material/table';
import { CommonUtilityService } from '../../../../services/common-utility-service';
import { SearchFilterService } from 'src/app/services/search-filter.service';
import { BulkUploadServiceService } from 'src/app/services/bulk-upload-service.service';
import { Subscription } from 'rxjs';
import { AppSettingService } from 'src/app/services/app-setting.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { SyncfusionService } from 'src/app/services/syncfusion.service';
import { MatDialog } from '@angular/material/dialog';
import { StockFormOrganismComponent } from 'src/app/shared/organisms/stock-management/stock-form-organism/stock-form-organism.component';

@Component({
  selector: 'app-stock-criteria-table',
  templateUrl: './stock-criteria-table.component.html',
  styleUrls: ['./stock-criteria-table.component.scss']
})
export class StockCriteriaTableComponent implements OnInit {

  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;
  ELEMENT_DATA: any[];
  dataSource = new MatTableDataSource<any>();
  displayedColumns = stockCriteriaColumns;
  add = false;
  update = false;
  searchField;
  nextLink:string
  nextStockCriteriaList$ = new Subscription();

  clearSearchField() {
    this.searchField = '';
    this.dataSource = new MatTableDataSource<any>(this.ELEMENT_DATA);
  }

  constructor(private service: StockService,
    private utilService: CommonUtilityService,
    private searchService: SearchFilterService,
    private bulkService: BulkUploadServiceService,
    private syncfusion: SyncfusionService,
    private settingService: AppSettingService,
    private snack: MatSnackBar,
    private dialog: MatDialog) {}

  ngOnInit() {
   this.service.getAllStock().subscribe((res: any) => {
      this.ELEMENT_DATA = res.stockCriterias;
      this.dataSource = new MatTableDataSource<any>(this.ELEMENT_DATA);
      this.dataSource.sort = this.sort;
      this.dataSource.paginator = this.paginator;
	  if(res) {
        this.nextLink = res?._links?.next?.href;
       }
   });
   const accessRules = JSON.parse(this.utilService.getCookie('accessRules')).accessRules;
   accessRules.map(each => {
    if (each.entity === 'stockcriteria') {
        this.add = each.create;
        this.update = each.update;
    }
   });
  }
  public doFilter = (value: string) => {
    //this.dataSource.filter = value.trim().toLocaleLowerCase();
    if(!value) {
      this.dataSource = new MatTableDataSource<any>(this.ELEMENT_DATA);
      this.dataSource.sort = this.sort;
      this.dataSource.paginator = this.paginator;
    }

    else this.searchService.searchFilter('stockcriteria', value, 'materialCode').subscribe((res: any) => {
      this.dataSource = new MatTableDataSource<any>(res.stockCriterias);
    })
  }
  openStockFormModal(materialCode?: string, locationCode?: string) {
    const dialogRef = this.dialog.open(StockFormOrganismComponent, {
      width: '400px',
      position: { right: '0px' , top: '64px'} ,
      data: { materialCode, locationCode }
    });
    dialogRef.afterClosed().subscribe(() => {
      // Refresh data or handle any post-dialog logic if needed
      this.ngOnInit(); // Refresh the table data
    });
  }

  downLoadReport() {
    // this.bulkService.downLoadReport('stockcriteria');
    if(this.settingService.isRemoteGridMode()){
      const data = {
        downloadEntity: true
      };
      const reportName = 'Stock_Criteria';
      this.syncfusion.exportDataToExcel(data,reportName).subscribe((response) => {
        if (response.status === 307) {
          this.bulkService.downLoadReport('stockcriteria')
        } else {
          this.snack.open("Download triggered Successfully", "close", {
            duration: 4000,
            horizontalPosition: "center",
            verticalPosition: "top",
          });
        }
      }, (error) => {
        console.error('Error in Excel Export:', error);
      });

    }else{
      this.bulkService.downLoadReport('stockcriteria');
    } 
  }

  getNextStockCriteria() {
    if(this.nextLink) {
      this.nextStockCriteriaList$ = this.service.getNextStockCriteria(this.nextLink).subscribe((res: any) => {
        this.nextLink = res?._links?.next?.href;
        this.ELEMENT_DATA.push(... res.stockCriterias);
        this.dataSource = new MatTableDataSource<any>(this.ELEMENT_DATA);
        this.dataSource.sort = this.sort;
        this.dataSource.paginator = this.paginator;
        this.dataSource.paginator.nextPage();
      });
    }
  }

    pageChanged(event) {
    if (this.nextLink) {
      if (event.previousPageIndex > event.pageIndex) {
      } else {
          this.getNextStockCriteria();
      }
    }
  }

  ngOnDestroy() {
    this.nextStockCriteriaList$.unsubscribe();
  }

}
