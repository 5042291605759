<ejs-grid #grid *ngIf="dynamic_headers.length > 0 && data" class="full-width-table overRide-gridPosition"
  [dataSource]="data" [allowPaging]="pagination" [allowFiltering]="true" [allowSorting]="true"
  [filterSettings]="filterOption" [allowSelection]="false" [enableHover]="false" [enableHeaderFocus]="true"
  [allowExcelExport]="true" [allowPdfExport]="true" [showColumnChooser]="true" [allowSelection]="true"
  [frozenColumns]="freezeColumns" [height]="isOptionRequired ? returnsAnalyzerOptions?'200':'400' : '600'"
  [toolbar]="toolbar" (toolbarClick)="toolbarClick($event)" (actionBegin)="actionBegin($event)"
  [loadingIndicator]="loadingIndicator" (rowSelected)="onRowSelected($event)" [pageSettings]="pageSettings"
  [editSettings]="editSettings" allowTextWrap='true' (rowDataBound)='rowDataBound($event)'
  [textWrapSettings]='wrapSettings' (dataStateChange)='dataStateChange($event)'>
  <e-columns>
    <ng-container *ngFor="let header of dynamic_headers">
      <e-column *ngIf="
          !header.image &&
          !header.percentage &&
          !header.state &&
          !header.pins &&
          header.allowFiltering &&
          header.field != 'openPOQty' &&
          header.field != 'canOrderQty' &&
          header.field != 'unavailableSkuCodes' &&
          header.field != 'skuStockDetail'
        " [field]="header.field" [headerText]="header.headerText" [allowFiltering]="header.allowFiltering"
        [filter]="header.filter" [width]="header.width" [isPrimaryKey]="header.field ==='materialCode' ? true : false">
        <ng-template *ngIf="header.style" #template let-data>
          <div [style]="header.style">
            {{ data[header.field] }}
          </div>
        </ng-template>
        <ng-template *ngIf="!header.style" #template let-data>
          <div *ngIf="!header.field?.startsWith('c_')">
            {{ data[header.field] }}
          </div>
          <div class="edit_cells" *ngIf="header.field?.startsWith('c_')">

            <input type="text" class="edit-input" [value]="data[header.field]"
              (input)="onCellEdit($event, header.field, data)" />
            <mat-icon class="input-edit-icon">edit</mat-icon>
            <span class="trend-container">
              <span [ngClass]="data[header.field] | getChangeDirection:data[header.field.slice(2)]">{{data[header.field]
                | getChangePercent:data[header.field.slice(2)]}}
                <span class="negative-icon"><mat-icon>arrow_drop_down</mat-icon></span>
                <span class="postive-icon"><mat-icon>arrow_drop_up</mat-icon></span>
              </span>
            </span>
          </div>
        </ng-template>

      </e-column>

      <e-column *ngIf="
          !header.image &&
          !header.percentage &&
          !header.state &&
          !header.pins &&
          !header.allowFiltering &&
          header.field != 'openPOQty' &&
          header.field != 'canOrderQty' &&
          header.field != 'unavailableSkuCodes' &&
          header.field != 'skuStockDetail'
        " [field]="header.field" [headerText]="header.headerText" [allowFiltering]="false" [width]="header.width">
        <ng-template *ngIf="header.style" #template let-data>
          <div [style]="header.style">
            {{ data[header.field] }}
          </div>
        </ng-template>
        <ng-template *ngIf="!header.style" #template let-data>
          <div *ngIf="header.field != 'customNormValue' && header.field !='createWorkOrder'"
            [ngClass]="header.field.includes('RateOfSale') ? 'centerAlign' : ''">
            {{ data[header.field] }}<mat-icon (click)="handlePOPUP(data,header)"
              *ngIf="header.field =='decision'">visibility</mat-icon>
          </div>
          <div *ngIf="header.field == 'analysis'">
            <a class="underlined-link" (click)="showMore(data)">Click to Know More</a>
          </div>
          <div style="display: flex; gap: 5px" class="row" *ngIf="header.field == 'input'">
            <input type="text" />
            <button class="btn btn-warning">Save</button>
          </div>
          <div class="action" *ngIf="header.field == 'action'">
            <button class="btn btn-success" (click)="action('accepted', data.id)">
              Accept
            </button>
            <button ejs-button class="btn btn-danger" (click)="action('rejected', data.id)">
              Reject
            </button>
          </div>
          <div class="action" *ngIf="header.field == 'createWorkOrder'">
            <input [(ngModel)]="data.userWorkOrders" placeholder="Enter work orders" />
            <mat-icon matTooltip="Create Work Order"
              [ngClass]="{'green-color': verifyToEnableIconColor.id==data.materialCode}" class="confirm-work-orders"
              mat-button color="primary" (click)="handleConfirmation(data)">check_circle</mat-icon>
            <mat-icon class="confirm-work-orders" matTooltip="Bill of materials" mat-button color="secondary"
              (click)="showBOMStatus(data)">inventory</mat-icon>
          </div>

          <div class="action" *ngIf="header.field == 'customNormValue'">
            <input [formControl]="dynamicNormForm.controls[data.id]" placeholder="Enter Custom Norm Value" />
            <button class="btn btn-primary" (click)="action('update', data.id)">
              Update
            </button>
          </div>
        </ng-template>
      </e-column>

      <e-column *ngIf="header.field == 'openPOQty' || header.field == 'canOrderQty'" [field]="header.field"
        [headerText]="header.headerText" [allowFiltering]="true" [filter]="{'type': 'Excel'}" [width]="header.width">
        <ng-template #template let-data>
          <ng-container *ngIf="data[header.field] =='openPOQty'; else elseTemplate">
            <div class="info-icon cursor-pointer" (click)="onOpenDialog(data, header.field)">
              <i class="bi bi-info-circle-fill"></i><span>&nbsp;&nbsp;</span>{{
              data[header.field]
              }}
            </div>
            <div *ngIf="data[header.field] == 0">
              {{ data[header.field] }}
            </div>
          </ng-container>
          <ng-template #elseTemplate>
            <div *ngIf="data.shortage_json !== '[]'" class="info-icon cursor-pointer"
              (mouseleave)="data.showOrders = false">
              {{
              data[header.field]
              }}<span>&nbsp;&nbsp;</span><i class="bi bi-info-circle-fill"
                (click)="onOpenDialog(data, header.field)"></i>
            </div>
            <div class="skus-bubble sku-left" *ngIf="
              data.showOrders &&
              showOrderQTY?.length !== 0
            ">

              <table class="show-orders">
                <tr>
                  <th>Code</th>
                  <th>Qty Shortfall</th>
                </tr>
                <tr *ngFor="let objItem of showOrderQTY">
                  <td>{{objItem.code}}</td>
                  <td>{{objItem.shortage}}</td>
                </tr>
              </table>
            </div>
            <div *ngIf="data.shortage_json === '[]'">
              {{ data[header.field] }}
            </div>
          </ng-template>
        </ng-template>
      </e-column>

      <e-column *ngIf="
          header.field == 'unavailableSkuCodes'
        " [field]="header.field" [headerText]="header.headerText" [allowFiltering]="false" [width]="header.width">
        <ng-template #template let-data>
          <div *ngIf="data[header.field].includes(',')" class="info-icon cursor-pointer"
            (mouseenter)="data.showSkus = true" (mouseleave)="data.showSkus = false" (mouseleave)="remove()">
            {{ data[header.field] | slice : 0 : 15 }}
            <a class="underlined-link" (click)="SKUs(data)">
              "show more"
            </a>
            <div class="skus-bubble sku-left" *ngIf="
                data.showSkus &&
                unavailableData &&
                unavailableData?.length !== 0
              ">
              <ol>
                <li *ngFor="let item of unavailableData">{{ item }}</li>
              </ol>
            </div>
          </div>
          <div *ngIf="!data[header.field].includes(',')">
            {{ data[header.field] }}
          </div>
        </ng-template>
      </e-column>
      <e-column *ngIf="header.field == 'skuStockDetail'" [field]="header.field" [headerText]="header.headerText"
        [allowFiltering]="false" [width]="header.width">
        <ng-template #template let-data>
          <div class="info-icon cursor-pointer" (mouseenter)="data.showSkuDetails = true"
            (mouseleave)="data.showSkuDetails = false" (mouseleave)="remove()">
            <a class="underlined-link" (click)="SKUs(data)">
              Get Stock Info
            </a>
            <div class="skus-bubble sku-left" *ngIf="
                data.showSkuDetails &&
                skuStockDetail &&
                skuStockDetail?.length !== 0
              ">
              <table>
                <tr *ngFor="let sk of skuStockDetail | keyvalue">
                  <th>
                    {{ sk.key }}
                  </th>
                  <td>
                    {{ sk.value }}
                  </td>
                </tr>
              </table>
            </div>
          </div>
        </ng-template>
      </e-column>

      <e-column *ngIf="header.image" [field]="header.field" [headerText]="header.headerText" [allowFiltering]="false"
        [width]="header.width">
        <ng-template #template let-data>
          <div *ngIf="data[header.field]" class="info-icon cursor-pointer">
            <div (mouseenter)="data.showImage = true" (mouseleave)="data.showImage = false">
              <img style="float: left" [src]="data[header.field]" alt="Image" height="30" width="30" />
              <div class="speech-bubble left" *ngIf="data.showImage">
                <img [src]="data[header.field]" alt="Image" />
              </div>
            </div>
          </div>
          <div *ngIf="!data[header.field]">
            <mat-icon>hide_image</mat-icon>
          </div>
        </ng-template>
      </e-column>

      <e-column *ngIf="header.percentage" [field]="header.field" [headerText]="header.headerText"
        [allowFiltering]="false" [width]="header.width">
        <ng-template #template let-data>
          <div>{{ data[header.field] | number : "1.2-2" }}%</div>
        </ng-template>
      </e-column>

      <e-column *ngIf="header.state" [field]="header.field" [headerText]="header.headerText" [allowFiltering]="true"
        [filter]="{ type: 'CheckBox' }" [width]="header.width">
        <ng-template #template let-data>
          <div class="status {{ data[header.field] }}">
            {{ data[header.field] }}
          </div>
        </ng-template>
      </e-column>
      <e-column *ngIf="header.pins" [field]="header.field" [headerText]="header.headerText" [allowFiltering]="true"
        [width]="header.width">
        <ng-template #template let-data>
          <div style="display: flex;align-items: center;gap: 5px;">
            {{ data[header.field] }}
            <app-pins-template-molecule [header]="header" [data]="data[header.field]"
              (fetchPinCount)="refreshPinCount()" [cachedPinCounts]="cachedPinCounts"></app-pins-template-molecule>
          </div>
        </ng-template>
      </e-column>
    </ng-container>
    <e-column *ngIf="saveTemplate" headerText="Actions" width="100" [template]="saveTemplate"></e-column>
  </e-columns>
</ejs-grid>

<ejs-dialog class="modal rounded" *ngIf="dialogVisible" id="dialog" #ejDialog [isModal]="true" width="500px"
  height="300px" [visible]="dialogVisible">
  <button (click)="onOverlayClick()" type="button" class="close" aria-label="Close">
    <span aria-hidden="true">&times;</span>
  </button>
  <div *ngIf="dataSourcePurchaseOrder.data.length">
    <h2 class="text-center">Purchase Order Details</h2>
    <h4 class="text-center font-weight-light font-italic">
      Material - {{ infoData.materialCode }}, Location -
      {{ infoData.locationCode }}
    </h4>
    <div class="table-container">
      <table class="w-100" *ngIf="dataSourcePurchaseOrder" mat-table [dataSource]="dataSourcePurchaseOrder">
        <ng-container *ngFor="
            let disCol of ['code', 'supplierCode', 'qty', 'purchaseOrderDate'];
            let i = index
          " matColumnDef="{{ disCol }}">
          <th class="header_dialog" mat-header-cell *matHeaderCellDef>
            {{ modifyName(disCol) }}
          </th>
          <td class="text-center" mat-cell *matCellDef="let element">
            {{ element[disCol] }}
          </td>
        </ng-container>
        <tr mat-header-row *matHeaderRowDef="[
            'code',
            'supplierCode',
            'qty',
            'purchaseOrderDate'
          ]"></tr>
        <tr mat-row *matRowDef="
            let row;
            columns: ['code', 'supplierCode', 'qty', 'purchaseOrderDate']
          "></tr>
      </table>
    </div>
  </div>
  <!-- <div *ngIf="!dataSourcePurchaseOrder.data.length" class="dialog-container">
    <img [src]="imgUrl" class="img-fluid" alt="Image" />
  </div> -->
  <div *ngIf="unavailableFlag" class="dialog-container">
    <h2 class="text-center">Unavailable SKUs</h2>
    <div class="list-align">
      <ol>
        <li style="font-size: larger" *ngFor="let item of unavailableData">
          {{ item }}
        </li>
      </ol>
    </div>
  </div>
  <ng-container *ngIf="createWorkOrdersFromUser">
    <div class="create-orders-confirm">
      <h3>You are about to create a work order of qty <strong>{{createWorkOrdersFromUser.qty}}</strong>. This will cause
        <strong>'Excess' inventory of
          {{getConfigDataForSpecificRow.piCreatedToday-getConfigDataForSpecificRow.piToBeCreated+createWorkOrdersFromUser.qty}}</strong>
        in the system. Are you sure you want to continue?
      </h3>
    </div>
    <div class="d-flex justify-content-around">
      <button type="button" style="background-color: gray; color: white;" class="btn btn-lg"
        (click)="onOverlayClick()">Cancel</button>
      <button type="button" style="background-color: #ffa000;" class="btn btn-lg"
        (click)="handleCreateWorkOrders()">Confirm</button>
    </div>
  </ng-container>
</ejs-dialog>


<ejs-dialog class="modal rounded" *ngIf="bomDialogVisible" id="bom-dialog" #ejDialog [isModal]="true" width="90%"
  height="auto" [visible]="bomDialogVisible">
  <button (click)="onOverlayClick()" type="button" class="close" aria-label="Close"><span
      aria-hidden="true">&times;</span></button>
  <app-bom-availability-state [sku]="selected_bomdata.materialCode" [location]="selected_bomdata.locationCode"
    displayName="Bill of Materials Availability Status"></app-bom-availability-state>
</ejs-dialog>