<ejs-dialog #configureDialog [visible]="dialogVisible" [header]="header" [content]="content" [width]="'39%'"
  [showCloseIcon]='true' (close)="onClose()">
  <ng-template #content>
    <div class="dropdown-cont" *ngIf="nodeType === 'Entity'" [formGroup]="entityFormGroup">
      <mat-form-field>
        <mat-label>Select Entity</mat-label>
        <mat-select (selectionChange)="onEntityChange($event.value)" formControlName="entity">
          <mat-option *ngFor="let entity of entities" [value]="entity.code">{{entity.name}}</mat-option>
        </mat-select>
        <mat-error *ngIf="entityFormGroup.get('entity').invalid && entityFormGroup.get('entity').touched" class="error">
          Entity is required.
        </mat-error>
      </mat-form-field>
      <div *ngFor="let condition of preFilterConditions.controls; let i = index" class="prefilter-condition-card-cont"
        [formGroup]="condition">
        <mat-card class="prefilter-condition-card">
          <mat-form-field>
            <mat-label>Select Attribute</mat-label>
            <mat-select (selectionChange)="onAttributeChange(condition, $event.value)"
              formControlName="compareAttribute">
              <mat-option *ngFor="let attribute of entityAttributes"
                [value]="attribute.code">{{attribute.name}}</mat-option>
            </mat-select>
            <mat-error *ngIf="condition.get('compareAttribute').invalid && condition.get('compareAttribute').touched"
              class="error">
              Attribute is required.
            </mat-error>
          </mat-form-field>
          <mat-form-field>
            <input matInput formControlName="compareValue" placeholder="Enter Value">
            <mat-error *ngIf="condition.get('compareValue').invalid && condition.get('compareValue').touched"
              class="error">
              Value is required.
            </mat-error>
          </mat-form-field>
          <mat-form-field>
            <mat-label>Select Comparison</mat-label>
            <mat-select formControlName="conditionType">
              <mat-option *ngFor="let option of comparisonOptions" [value]="option">{{option}}</mat-option>
            </mat-select>
            <mat-error *ngIf="condition.get('conditionType').invalid && condition.get('conditionType').touched"
              class="error">
              Comparison is required.
            </mat-error>
          </mat-form-field>
        </mat-card>
        <button mat-icon-button color="warn" (click)="removePrefilterCondition(i)">
          <mat-icon>delete</mat-icon>
        </button>
      </div>
      <button class="add-btn" mat-raised-button (click)="addPrefilterCondition()"><mat-icon
          style="font-size: 20px;">add</mat-icon> Add Prefilter Conditions</button>
    </div>
    <div class="dropdown-cont" *ngIf="nodeType === 'Step'" [formGroup]="stepFormGroup">
      <mat-form-field>
        <mat-label>Enter Code</mat-label>
        <input matInput formControlName="code" placeholder="Enter Code">
        <mat-error *ngIf="stepFormGroup.get('code').invalid && stepFormGroup.get('code').touched" class="error">
          Code is required.
        </mat-error>
      </mat-form-field>
    </div>
    <div class="dropdown-cont" *ngIf="nodeType === 'Condition'" [formGroup]="conditionFormGroup">
      <mat-form-field>
        <mat-label>Enter Code</mat-label>
        <input matInput formControlName="code" placeholder="Enter Code">
        <mat-error *ngIf="conditionFormGroup.get('code').invalid && conditionFormGroup.get('code').touched"
          class="error">
          Code is required.
        </mat-error>
      </mat-form-field>
      <mat-form-field>
        <mat-label>Select Attribute</mat-label>
        <mat-select formControlName="compareAttribute">
          <mat-option *ngFor="let attribute of entityAttributes"
            [value]="attribute.code">{{attribute.name}}</mat-option>
        </mat-select>
        <mat-error
          *ngIf="conditionFormGroup.get('compareAttribute').invalid && conditionFormGroup.get('compareAttribute').touched"
          class="error">
          Attribute is required.
        </mat-error>
      </mat-form-field>
      <mat-form-field>
        <input matInput formControlName="compareValue" placeholder="Enter Value">
        <mat-error
          *ngIf="conditionFormGroup.get('compareValue').invalid && conditionFormGroup.get('compareValue').touched"
          class="error">
          Value is required.
        </mat-error>
      </mat-form-field>
      <mat-form-field>
        <mat-label>Select Condition Type</mat-label>
        <mat-select formControlName="conditionType">
          <mat-option *ngFor="let option of conditionTypes" [value]="option">{{option}}</mat-option>
        </mat-select>
        <mat-error
          *ngIf="conditionFormGroup.get('conditionType').invalid && conditionFormGroup.get('conditionType').touched"
          class="error">
          Condition is required.
        </mat-error>
      </mat-form-field>
    </div>
    <div class="dropdown-cont" *ngIf="nodeType === 'Evaluation'" [formGroup]="evaluationFormGroup">
      <mat-form-field>
        <mat-label>Select Condition</mat-label>
        <mat-select formControlName="conditions" (selectionChange)="onDropdownChange('conditions')">
          <mat-option *ngFor="let code of conditionCodes" [value]="code">{{code}}</mat-option>
        </mat-select>
      </mat-form-field>
      <mat-form-field>
        <mat-label>Select Evaluation Type</mat-label>
        <mat-select formControlName="evaluationTypes" (selectionChange)="onDropdownChange('evaluationTypes')">
          <mat-option *ngFor="let type of evaluationTypes" [value]="type">{{type}}</mat-option>
        </mat-select>
      </mat-form-field>
      <mat-form-field>
        <mat-label>Evaluation Value</mat-label>
        <input matInput formControlName="evaluationValue" placeholder="Evaluation Value">
        <mat-error
          *ngIf="evaluationFormGroup.get('evaluationValue').invalid && evaluationFormGroup.get('evaluationValue').touched"
          class="error">
          Value is required.
        </mat-error>
      </mat-form-field>
    </div>
    <div class="dropdown-cont" *ngIf="nodeType === 'Action'" [formGroup]="actionFormGroup">
      <mat-form-field>
        <mat-label>Enter Code</mat-label>
        <input matInput formControlName="code" placeholder="Enter Code">
        <mat-error *ngIf="actionFormGroup.get('code').invalid && actionFormGroup.get('code').touched" class="error">
          Code is required.
        </mat-error>
      </mat-form-field>
      <mat-form-field>
        <mat-label>Select Entity</mat-label>
        <mat-select (selectionChange)="onActionEntityChange($event.value)" formControlName="entity">
          <mat-option *ngFor="let entity of entities" [value]="entity.code">{{ entity.name }}</mat-option>
        </mat-select>
        <mat-error *ngIf="actionFormGroup.get('entity').invalid && actionFormGroup.get('entity').touched" class="error">
          Entity is required.
        </mat-error>
      </mat-form-field>
      <mat-form-field>
        <mat-label>Select Action Type</mat-label>
        <mat-select formControlName="actionType">
          <mat-option *ngFor="let type of actions" [value]="type">{{ type }}</mat-option>
        </mat-select>
        <mat-error *ngIf="actionFormGroup.get('actionType').invalid && actionFormGroup.get('actionType').touched"
          class="error">
          Action type is required.
        </mat-error>
      </mat-form-field>
      <div *ngFor="let ele of attributes.controls; let i = index" class="prefilter-condition-card-cont"
        [formGroup]="ele">
        <mat-card class="prefilter-condition-card">
          <mat-form-field>
            <mat-label>Select Attribute</mat-label>
            <mat-select formControlName="attribute" (selectionChange)="checkIfAttributeExists($event.value)">
              <mat-option *ngFor="let attr of actionEntityAttr" [value]="attr.code">{{attr.name}}</mat-option>
            </mat-select>
            <mat-error
              *ngIf="ele.get('attribute').invalid && ele.get('attribute').touched && !ele.get('attribute').hasError('attributeExists')"
              class="error">
              Attribute is required.
            </mat-error>
            <mat-error class="error"
              *ngIf="ele.get('attribute').hasError('attributeExists') && ele.get('attribute').touched">
              Attribute already exists.
            </mat-error>
          </mat-form-field>
          <!-- <mat-checkbox formControlName="unique">Unique</mat-checkbox> -->
          <mat-checkbox formControlName="lookup" (change)="toggleLookup(i)">LookUp</mat-checkbox>
          <ng-container *ngIf="!ele.get('lookup')?.value">
            <mat-form-field>
              <input matInput placeholder="Value" formControlName="value">
              <mat-error *ngIf="ele.get('value').invalid && ele.get('value').touched" class="error">
                Value is required if LookUp is not selected.
              </mat-error>
            </mat-form-field>
          </ng-container>

          <ng-container *ngIf="ele.get('lookup')?.value">
            <mat-form-field>
              <!-- <input matInput placeholder="Lookup Attribute" formControlName="lookupAttribute" /> -->
              <mat-label>Select Lookup Attribute</mat-label>
              <mat-select formControlName="lookupAttribute">
                <mat-option *ngFor="let attr of entityAttributes" [value]="attr.code">{{ attr.name }}</mat-option>
              </mat-select>
              <mat-error
                *ngIf="ele.get('lookupAttribute').hasError('lookupInvalid') && ele.get('lookupAttribute').touched">
                Lookup Attribute is required if LookUp is selected.
              </mat-error>
            </mat-form-field>
          </ng-container>
          <mat-error *ngIf="ele.errors?.valueOrLookupRequired" class="error">
            Either Value or Lookup Attribute is required.
          </mat-error>
        </mat-card>

        <button mat-icon-button color="warn" (click)="removeAttribute(i)">
          <mat-icon>delete</mat-icon>
        </button>
      </div>
      <button class="add-btn" mat-raised-button (click)="addAttributes()"><mat-icon
          style="font-size: 20px;">add</mat-icon> Add Attributes</button>
    </div>
  </ng-template>

  <ng-template #footerTemplate>
    <button class="e-control e-btn" mat-raised-button color="warn" (click)="onClose()">Cancel</button>
    <button class="e-control e-btn" mat-raised-button color="primary" (click)="onSave()">Save</button>
  </ng-template>
</ejs-dialog>