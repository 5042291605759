import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class DataSharingService {
  // Step 1: Create a BehaviorSubject to store and emit the edited data
  private editedDataSubject = new BehaviorSubject<any[]>([]);
  editedData$ = this.editedDataSubject.asObservable();

  // Method to update the edited data
  setEditedData(data: any[]) {
    this.editedDataSubject.next(data);
  }
}
